
export default {
    name: 'Influencer-RegisterBasic',
    props: {
        country: Object,
        middleEmit: Object
    },
    data() {
        return {
            clear: false,
            popup: {
                visible: false,
            },
            form: {}
        }
    },
    computed: {},
    watch: {
        form: {
            handler(n, o) {
                this.clear && clearTimeout(this.clear)
                this.clear = setTimeout(() => {
                    this.middleEmit.fn('emitFormBasic', n)
                }, 200)
            },
            deep: true
        }
    },
    created() {},
    mounted() {
        this.initialSet()
    },
    methods: {
        // 提交
        formSubmit(e) {
            // 数据存储
            this.$storage.set('influencer/registerBasic', this.form)
            this.middleEmit.fn('emitFormSubmit', e)
        },
        // 城市选择
        cancelCity() {
            this.popup.visible = false
        },
        confirmCity(e) {
            this.popup.visible = false
            this.form.country = e.englishName
        },
        handleSelect() {
            this.popup.visible = true
        },
        // 初始化
        initialSet() {
            this.form = this.$storage.get('influencer/registerBasic') || {}
        }
    },
}
