import { render, staticRenderFns } from "./OrderFilter.vue?vue&type=template&id=4883c954&scoped=true&"
import script from "./OrderFilter.vue?vue&type=script&lang=js&"
export * from "./OrderFilter.vue?vue&type=script&lang=js&"
import style0 from "./OrderFilter.vue?vue&type=style&index=0&id=4883c954&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4883c954",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnButton: require('/home/php1/m-nuxt/components/YfnButton.vue').default,YfnPopup: require('/home/php1/m-nuxt/components/YfnPopup.vue').default})
