
import fnApi from '/utils/common/methods'
import InfluencerHead from '../components/InfluencerHead'
import RegisterBasic from './components/RegisterBasic'
import RegisterMedia from './components/RegisterMedia'
import RegisterCode from './components/RegisterCode'
import RegisterSubmit from './components/RegisterSubmit'

export default {
    name: 'Influencer-Register',
    components: {
        InfluencerHead,
        RegisterBasic,
        RegisterMedia,
        RegisterCode,
        RegisterSubmit
    },
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            fnApi: fnApi,
            middleEmit: {},
            hasLogin: false,        // 是否登录
            title: '',              // 标题
            clear: '',              // 节流
            userId: '',             // 用户id
            promotionId: '',        // 推广id
            user: {},               // 网红信息
            userInfo: {},           // 用户信息
            country: {              // 国家
                list: []
            },
            query: {               // 地址栏参数
                key: '',           // 编辑
                user: {},          // 网红信息
            },
            check: {               // form校验
                hasCheck: false,   // 是否验证通过
                refArr: [],        // form ref
                filed: {}
            },
            form: {                // 表单数据
                basic: {},         // 基础信息
                submit: {}
            },
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {
        this.initialCreateSet()
    },
    mounted() {
        this.initialSet()
        // this.getCountryAjax()
        this.getUserInfoAjax()
    },
    methods: {
        // 获取国家列表
        getCountryAjax() {
            this.$api.common.getCountryList().then(response => {
                const res = response.result || [];
                this.country.list = res || []
                this.country.list.forEach(item => {
                    item.text = item.showName || item.englishName
                })
            })
        },
        // 获取用户信息
        getUserInfoAjax() {
            if(!this.hasLogin) {
                return
            }
            this.$api.user.getUseInfo({
                id: this.userId
            }).then(response => {
                const res = response.result || {};
                this.userInfo = res || {}
            })
        },
        // 网红注册验证
        registerInfluencerAjax() {
            // this.$api.influencer.setRegister({
            this.$api.influencer.checkInfluencer({
                data: JSON.stringify({
                    ...this.form.submit,
                    uid: this.promotionId
                })
            }).then(response => {
                const res = response || {};
                let status = false
                if(res.code && (res.code == 500 || res.code == 'error')) {
                    status = false
                } else {
                    status = res
                }
                if(status) {
                    this.$Toast('Submitted successfully')
                    this.$storage.set('influencer/register', {
                        ...this.form,
                        uid: this.promotionId
                    })
                    let clear = setTimeout(() => {
                        // this.$router.push({
                        //     name: 'Influencer-Verifying'
                        // })
                        this.$router.push({
                            name: 'Influencer-Code'
                        })
                        clearTimeout(clear)
                    }, 2000)
                }
            })
        },
        // 网红信息修改接口
        editInfluencerAjax() {
            this.$api.influencer.setEditLink({
                data: JSON.stringify({
                    ...this.user,
                    ...this.form.submit
                })
            }).then(response => {
                const res = response;
                if(res.code != 'error') {
                    this.$router.push({
                        name: 'Influencer-Setting'
                    })
                }
                this.hasRequest = false
            }).catch(err => {
                this.hasRequest = false
            })
        },
        // emit
        emitFormBasic(obj) { // 基础信息获取
            this.form.basic = obj || {}
        },
        emitFormSubmit(e) { // form submit
            // 设置参数
            this.setForm()
            return new Promise((resolve, reject) => {
                this.clear && clearTimeout(this.clear)
                this.clear = setTimeout(() => {
                    this.check.filed = e
                    // 校验
                    this.checkForm()
                    if(this.check.hasCheck) {
                        if(!this.query.key) {
                            // 注册接口
                            this.registerInfluencerAjax()
                        } else {
                            // 修改接口
                            this.editInfluencerAjax()
                        }
                    }
                    resolve(e)
                }, 50)
            })
        },
        // 提交
        checkForm() { // 校验
            this.check.hasCheck = !this.check.filed.errors
        },
        setForm() { // 设置提交参数
            this.form.submit = {}
            this.check.refArr.forEach(item => {
                this.form.submit = {
                 ...this.form.submit,
                 ...this.$refs[item].form
                }
            })
        },
        emitHandleSubmit() { // apply
            // 校验
            this.check.refArr.forEach(item => {
                this.$refs[item]['$refs']['form'].submit()
            })
            this.emitFormSubmit()
        },
        // set HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: 'Influencer Program',
                keywords: 'yfn, influencer',
                description: '✔Free gifted jewelry ✔10% commission ✔20% exclusive discount! Refer and get rewarded now.',
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
        // 初始化
        initialSet() {
            this.middleEmit.fn = (fn, options) => {
                this[fn] && this[fn](options)
            }
            this.hasLogin = this.$login()
            this.userId = this.$storage.get('userId')
            this.title = this.$translate('Complete Your Info')
            // 用户信息
            this.user = this.$storage.get('influencer/userInfo')
            // 编辑状态
            this.check.refArr = ['RegisterBasic', 'RegisterMedia', 'RegisterCode']
            this.query = this.$route.query
            if(this.query.key == 2) {
                this.check.refArr.splice(2, 1)
                this.title = 'Update your profile'
            }
            this.promotionId = this.query.uid || ''
            if(this.promotionId.includes('#')) {
                this.promotionId = this.promotionId.split('#')[0] || ''
            }
        },
        initialCreateSet() {
            this.country.list = this.$fnApi.mixData.influencerCountry()
        }
    },
}
