
export default {
    name: 'Influencer-RegisterCode',
    props: {
        login: Boolean,
        user: Object,
        forms: Object,
        middleEmit: Object
    },
    data() {
        return {
            hasRequest: false,
            countDown: {
                time: 60,
                visible: false,
            },
            form: {
                remarks: '',
                certifyCode: ''
            },
            codeTip: ''
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
    },
    methods: {
        // 获取code接口
        getCodeAjax() {
            this.$api.influencer.getCertifyCode({
                data: JSON.stringify({
                    sendType: 1,
                    nickName: this.forms.basic.name,
                    email: this.user.email
                })
            }).then(response => {
                const res = response.result;
                this.codeTip = `Verification Code has been sent to email ${this.user.email}`
                // 倒计时
                this.setCountDown()
            }).catch(err => {
                this.hasRequest = false
                this.$Toast(err.error_msg)
            })
        },
        // 获取code
        handleGet() {
            if(!this.login) {
                this.$router.push({
                    name: 'Login'
                })
                return
            }
            if(!this.forms.basic.name) {
                this.$Toast(this.$translate('Please enter your name'))
                return
            }
            if(this.hasRequest) {
                return
            }
            this.hasRequest = true
            this.getCodeAjax()
        },
        // 倒计时
        setCountDown() {
            this.countDown.visible = true
            this.countDown.time = 60 * 1000
            setTimeout(() => {
                this.$refs.countDown.start()
            }, 10)
        },
        finish() {
            this.hasRequest = false
            this.countDown.visible = false
        },
        // 提交
        formSubmit(e) {
            // 数据存储
            this.$storage.set('influencer/registerCode', {
                remarks: this.form.remarks
            })
            this.middleEmit.fn('emitFormSubmit', e)
        },
        // 初始化
        initialSet() {
            this.form = this.$storage.get('influencer/registerCode') || {}
        }
    },
}
