
import InfluencerHead from '../components/InfluencerHead'

export default {
    name: 'Influencer-Verifying',
    components: {
        InfluencerHead
    },
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            clear: '',
            skeleton: {
                visible: false,
            },
            status: '', // 0 等待审核 1 审核通过 2 失败 3 未申请
            url: ''
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.getApplyStatus()
    },
    methods: {
        // 删除申请接口
        deleteApplyAjax() {
            this.$api.influencer.deleteApply(Api.INFLUENCER_DELETE_APPLY).then(response => {
                const res = response || {};
                if(res.code && (res.code == 500 || res.code == 'error')) {
                    return
                }
                if(res) {
                    this.$router.push({
                        name: 'Influencer-Register'
                    })
                } else {
                    this.$Toast(`${this.$translate('Please try again')}!`)
                }
            })
        },
        // 跳转
        jumpRegister() {
            this.clear && clearTimeout(this.clear)
            this.clear = setTimeout(() => {
                if(this.status == 2) {
                    this.deleteApplyAjax()
                } else {
                    this.$router.push({
                        name: 'Influencer-Register'
                    })
                }
            }, 300)
        },
        // 网红申请状态
        getApplyStatus() {
            this.$api.influencer.getApplyStatus().then(response => {
                const res = response;
                this.skeleton.visible = false
                this.status = 0
                if(Object.prototype.toString.call(res) === '[object Object]') {
                    if(res.code && (res.code == 500 || res.code == 'error')) {
                        this.status = 2
                    } else {
                        this.status = res.result
                    }
                } else {
                    this.status = res
                }
                this.url = [0, 1].includes(this.status) ? 'https://cdn2.selleroa.com/yfn-upload/review/1686720031930.png' : 'https://cdn2.selleroa.com/yfn-upload/review/1686720034685.png'
                if(this.status == 1) {
                    this.$router.push({
                        name: 'Influencer-Mine'
                    })
                }
            })
        },
        // set HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: 'Influencer Program',
                keywords: 'yfn, influencer',
                description: '✔Free gifted jewelry ✔10% commission ✔20% exclusive discount! Refer and get rewarded now.',
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
    },
}
