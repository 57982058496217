
export default {
    name: 'Influencer-MinePopup',
    props: {
        popup: Object,
        coupon: Object,
        middleEmit: Object,
    },
    data() {
        return {
            hasRequest: false
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        // 修改link
        setEditLinkAjax() {
            this.$api.influencer.setEditLink({
                data: JSON.stringify({commissionUrl: this.popup.info.code})
            }).then(response => {
                const res = response;
                this.hasRequest = false
                this.popup.visible = false
                this.middleEmit.fn('emitEditLink')
            }).catch(err => {
                this.hasRequest = false
            })
        },
        // 修改code
        setEditCodeAjax() {
            this.$api.influencer.setEditCode({
                data: JSON.stringify({
                    code: this.popup.info.code,
                    id: this.coupon.list[1].id
                })
            }).then(response => {
                const res = response;
                this.hasRequest = false
                this.popup.visible = false
                this.middleEmit.fn('emitEditCode', res)
            }).catch(err => {
                this.hasRequest = false
            })
        },
        // 取消
        handleCancel() {
            this.popup.visible = false
        },
        // 编辑
        handleBtn() {
            if(this.hasRequest) {
                return
            }
            this.hasRequest = true
            if(this.popup.type == 1) {
                this.setEditLinkAjax()
            } else if(this.popup.type == 2) {
                this.setEditCodeAjax()
            }
        }
    },
}
