
export default {
    name: 'Influencer-RegisterMedia',
    props: {
        middleEmit: Object
    },
    data() {
        return {
            popup: {
                visible: false,
            },
            media: {
                i: 0,
                siteList: [],
                checkList: [],
            },
            form: {
                siteList: []
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
    },
    methods: {
        // 提交
        formSubmit(e) {
            // 数据存储
            this.$storage.set('influencer/registerMedia', this.form)
            this.middleEmit.fn('emitFormSubmit', e)
        },
        // 媒体
        handleDelete(obj) { // 删除
            if(this.form.siteList.length < 2) {
                this.$Toast(this.$translate('Fill in at least one item'))
                return
            }
            this.$Dialog.confirm({
                message: this.$translate(`Are you sure you want to delete?`),
                confirmButtonText: this.$translate('Sure'),
                confirmButtonColor: '#333',
                cancelButtonText: this.$translate('No'),
                cancelButtonColor: '#999999'
            }).then(() => {
                this.form.siteList.splice(obj.i, 1)
            }).catch(() => {
            })
        },
        handleSelect(obj) { // media选择
            this.popup.visible = true
            this.media.i = obj.i
        },
        confirmMedia(e) { // media选择确认
            this.$set(this.form.siteList, this.media.i, {
                ...this.form.siteList[this.media.i],
                mainSiteType: e.text
            })
            this.popup.visible = false
        },
        cancelMedia() { // media选择取消
            this.popup.visible = false
        },
        handleAdd() { // 添加media选项
            if(!(this.form.siteList.length >= this.media.checkList.length)) {
                this.form.siteList.push({
                    mainSiteType: '',
                    fans: '',
                    mainSiteUrl: ''
                })
            } else {
                this.$Toast('Add up to 6')
            }
        },
        // 初始化
        initialSet() {
            this.media.checkList = [
                { text: 'INSTAGRAM', id: 1 },
                { text: 'TIKTOK', id: 2 },
                { text: 'FACEBOOK', id: 3 },
                { text: 'YOUTUEB', id: 4 },
                { text: 'BLOG', id: 5 },
                { text: 'PINTEREST', id: 6 }]
            this.form = this.$storage.get('influencer/registerMedia') || {
                siteList: [{
                    mainSiteType: '',
                    fans: '',
                    mainSiteUrl: ''
                }]
            }
            this.form.siteList.forEach(item => {
                if(item.fans) {
                    item.fans = +item.fans
                }
            })
        }
    },
}
