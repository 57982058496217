import { render, staticRenderFns } from "./MinePopup.vue?vue&type=template&id=51e7af8d&scoped=true&"
import script from "./MinePopup.vue?vue&type=script&lang=js&"
export * from "./MinePopup.vue?vue&type=script&lang=js&"
import style0 from "./MinePopup.vue?vue&type=style&index=0&id=51e7af8d&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51e7af8d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnButton: require('/home/php1/m-nuxt/components/YfnButton.vue').default,YfnPopup: require('/home/php1/m-nuxt/components/YfnPopup.vue').default})
