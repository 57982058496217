
export default {
    name: 'Influencer-OrderFilter',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        fnApi: Object,
        middleEmit: Object,
        popup: Object,
        tab: Object,
    },
    data() {
        return {
            time: {
                startTime: '',
                endTime: '',
                currentDate: new Date(),
                minDate: '',
            },
            filterPopup: { // 时间弹窗
                visible: false
            },
            options: { // 选项
                commission: [],
                status: []
            },
            form: {},
        }
    },
    computed: {},
    watch: {},
    created() {
        this.initialSet()
    },
    mounted() {},
    methods: {
        // 时间选择
        handleTime(val) {
            if(!this.form.startCreateDate) {
                val = 1
            }
            this.filterPopup.type = val
            this.filterPopup.visible = true
            this.time.minDate = new Date(2019, 0, 1)
            if(val == 2 && this.time.startTime) {
                this.time.minDate = new Date(this.time.startTime)
            }
        },
        onConfirm(e) {
            this.form[['startCreateDate', 'endCreateDate'][this.filterPopup.type-1]] = e.getTime()
            this.time[['startTime', 'endTime'][this.filterPopup.type-1]] = `${e.getFullYear()}-${e.getMonth()+1}-${e.getDate()}`
            this.filterPopup.visible = false
        },
        onCancel() {
            this.filterPopup.visible = false
        },
        // 提交
        handleReset() { // 重置
            this.form = {}
            this.time.startTime = ''
            this.time.endTime = ''
            this.popup.visible = false
            this.middleEmit.fn('emitHandleFilter', this.form)
        },
        handleSubmit() { // 提交
            this.popup.visible = false
            this.middleEmit.fn('emitHandleFilter', this.form)
        },
        // 初始化
        initialSet() {
            this.options.commission = [{
                text: 'By LINK',
                id: 1
            }, {
                text: 'By CODE',
                id: 2
            }]
            this.options.status = [{
                text: 'Processing',
                id: 0
            }, {
                text: 'Shipped',
                id: 1
            }, {
                text: 'Delivered',
                id: 2
            }, {
                text: 'Done',
                id: 3
            }, {
                text: 'Canceled',
                id: 4
            }]
            this.options.earned = [{
                text: 'Pending',
                id: 0
            }, {
                text: 'Earned',
                id: 1
            }, {
                text: 'Not Earned',
                id: 2
            }]
        }
    },
}
