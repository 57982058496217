
import md5 from '/utils/md5';

export default {
    name: 'Influencer-PwdPopup',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        popup: Object,
        user: Object,
    },
    data() {
        return {
            hasRequest: false,
            form: {},
            check: { // 校验
                tip: '',
                hasCheck: false,
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        // 修改密码接口
        setChangPwdAjax() {
            this.$api.user.setChangePwd({
                email: this.user.emailAddress,
                pwd: md5(this.form.newPwd),
                rePwd: md5(this.form.tryPwd),
            }).then(response => {
                this.$Toast(this.$translate('change password successfully'))
                let clear = setTimeout(() => {
                    this.hasRequest = false
                    this.$router.push({
                        name: 'Login'
                    })
                    clearTimeout(clear)
                }, 500)
            }).catch(err => {
                this.hasRequest = false
            })
        },
        // 关闭
        handleCancel() {
            this.popup.visible = false
        },
        // 提交
        checkForm() { // 校验
            this.check.hasCheck = true
            this.check.tip = ''
            if(this.form.newPwd !== this.form.tryPwd) {
                this.check.tip = this.$translate('your new password is not same as confirm password')
            }
            if(!this.form.newPwd || !this.form.tryPwd) {
                this.check.tip = this.$translate('Please enter your password')
            }
            this.check.hasCheck = !this.check.tip
            if(!this.check.hasCheck) {
                this.$Toast(this.check.tip)
            }
        },
        handleSubmit() { // 提交按钮
            this.checkForm()
            if(this.check.hasCheck){
                this.hasRequest = true
                this.setChangPwdAjax()
            }
        },
    },
}
