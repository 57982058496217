
import fnApi from '/utils/common/methods'
import InfluencerHead from '../components/InfluencerHead'
import OrderTab from './components/OrderTab'
import OrderSale from './components/OrderSale'
import OrderGift from './components/OrderGift'
import OrderFilter from './components/OrderFilter'

export default {
    name: 'Influencer-Order',
    components: {
        InfluencerHead,
        OrderTab,
        OrderSale,
        OrderGift,
        OrderFilter
    },
    props: {},
    data() {
        return {
            hasLoadMore: false,   // 是否加载完成
            clear: '',            // 节流
            fnApi: fnApi,
            middleEmit: {},
            skeleton: {           // 骨架屏
                visible: true,
            },
            tab: {                // 标签栏
                id: 1,            // 1 销售订单数据 2 礼品订单数据
                list: []
            },
            sale: {               // 销售订单
                visible: false,
                list: []
            },
            gift: {               // 礼品订单
                visible: false,
                list: []
            },
            popup: {              // 弹窗
                visible: false,
            },
            form: {},
            page: {               // 分页
                pageSize: 20,
                pageNum: 1,
                totalPage: 0
            }
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {
        this.initialSet()
    },
    mounted() {
        this.loadMore()
        this.getSaleOrderAjax()
    },
    methods: {
        // 获取订单数据
        getSaleOrderAjax() { // 获取销售订单数据
            this.$api.influencer.getOrderSale({
                data: JSON.stringify({
                    ...this.form,
                    pageNum: this.page.pageNum,
                    pageSize: this.page.pageSize
                })
            }).then(response => {
                const res = response.result;
                this.hasLoadMore = true
                this.skeleton.visible = false
                this.sale.list = [...this.sale.list, ...(res.list || [])]
                this.sale.visible = this.sale.list.length
                this.page.totalPage = res.totalPage
                // 销售订单数据处理
                this.setSaleData()
            })
        },
        getGiftOrderAjax() { // 获取礼品订单数据
            this.$api.influencer.getOrderGift({
                data: JSON.stringify({
                    ...this.form,
                    pageNum: this.page.pageNum,
                    pageSize: this.page.pageSize
                })
            }).then(response => {
                const res = response.result;
                this.hasLoadMore = true
                this.skeleton.visible = false
                this.gift.list = [...this.gift.list, ...(res.list || [])]
                this.gift.visible = this.gift.list.length
                this.page.totalPage = res.totalPage
                // 礼品订单数据处理
                this.setGiftData()
            })
        },
        setSaleData() { // 销售订单数据处理
            this.sale.list.forEach(item => {
                item.reTime = this.stampToTime(item.createDate)
                item.reStatus = ['Processing', 'Shipped', 'Delivered', 'Done', 'Canceled'][item.influencerOrderStatus]
                item.reInvite = ['Link', 'Code'][item.commissionType-1]
                item.reEarned = ['Pending', 'Earned', 'Not Earned'][item.commissionEarned]
            })
        },
        setGiftData() { // 礼品订单数据处理
            this.gift.list.forEach(item => {
                item.reTime = this.stampToTime(item.createDate)
                item.reStatus = ['Processing', 'Shipped', 'Delivered', 'Done', 'Canceled'][item.influencerOrderStatus]
            })
        },
        stampToTime(val) { // 时间戳转时间
            let time = this.$dayjs(val).format('YYYY-MM-DD HH:mm');
            return time
        },
        // tab
        emitHandleTab() {
            this.page.pageNum = 1
            this.page.totalPage = 0
            this.clear && clearTimeout(this.clear)
            this.clear = setTimeout(() => {
                if(this.tab.id == 1) {
                    this.sale.list = []
                    this.getSaleOrderAjax()
                } else {
                    this.gift.list = []
                    this.getGiftOrderAjax()
                }
            }, 100)
        },
        handleFilter() {
            this.popup.visible = !this.popup.visible
        },
        // 筛选
        emitHandleFilter(obj) {
            this.form = obj || {}
            this.emitHandleTab()
        },
        // 复制
        emitHandleCopy(obj) {
            this.fnApi.copy.initial(obj.yfnOrderId)
            this.$Toast('Copied')
        },
        // 触底加载更多
        loadMore() {
            this.fnApi.pullRefresh.load(e => {
                if(e.isIntersecting && this.hasLoadMore && this.page.pageNum < this.page.totalPage) {
                    this.hasLoadMore = false
                    this.page.pageNum += 1
                    if(this.tab.id == 1) {
                        this.getSaleOrderAjax()
                    } else {
                        this.getGiftOrderAjax()
                    }
                }
            })
        },
        // set HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: 'Influencer Program',
                keywords: 'yfn, influencer',
                description: '✔Free gifted jewelry ✔10% commission ✔20% exclusive discount! Refer and get rewarded now.',
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
        // 初始化设置
        initialSet() {
            this.middleEmit.fn = (fn, options) => {
                this[fn] && this[fn](options)
            }
            this.tab.list = [{
                title: 'Sale Orders',
                id: 1
            }, {
                title: 'Gift Orders',
                id: 2
            }]
        }
    },
}
