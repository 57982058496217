
export default {
    name: 'Influencer-RegisterSubmit',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        query: Object,
        middleEmit: Object
    },
    data() {
        return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        handleSubmit() {
            this.middleEmit.fn('emitHandleSubmit')
        }
    },
}
