
import fnApi from '/utils/common/methods'
import InfluencerHead from '../components/InfluencerHead'
import Mine from '../mine/index'
import PwdPopup from './components/pwdPopup'

export default {
    name: 'Influencer-Setting',
    components: {
        InfluencerHead,
        Mine,
        PwdPopup
    },
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            hasLogin: false,   // 是否登录
            fnApi: fnApi,
            middleEmit: {},
            user: {},          // 用户信息
            card: {},          // 卡信息
            cardIcon: [],      // 卡图标
            setting: {         // 设置信息
                list: [],
                accountList: []
            },
            popup: {
                visible: false
            }
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {
        this.initialSet()
    },
    mounted() {
        this.getUserInfoAjax()
        this.getCardInfoAjax()
    },
    methods: {// 网红信息接口
        getUserInfoAjax() {
            this.$api.influencer.getUserInfo().then(response => {
                const res = response.result || {};
                this.user = res
                // 设置用户基础信息
                this.setUserData()
            })
        },
        // 信用卡信息接口
        getCardInfoAjax() {
            this.$api.influencer.getCardInfo().then(response => {
                const res = response.result || [];
                this.card = (res && res[0]) || {}
                // 设置卡数据
                this.setCardInfoData()
            })
        },
        // 设置用户基础信息数据
        setUserData() {
            this.setting.list = [{
                title: 'Name',
                sub: this.user.name,
            }, {
                title: 'Gender',
                sub: this.user.gender,
            }, {
                title: 'Country',
                sub: this.user.country,
            }]
            let siteList = this.user.siteList || []
            siteList.forEach(item => {
                this.setting.list.push({
                    ...item,
                    title: item.mainSiteType,
                    sub: item.mainSiteUrl,
                })
            });
        },
        // 设置卡数据
        setCardInfoData() {
            let filter = this.cardIcon.filter(item => {
                return item.value === this.card.accountType
            })[0] || {}
            this.card.icon = filter.url
            this.setting.accountList = [{
                title: 'Password',
                sub: 'Change'
            }, {
                title: 'Account',
                sub: this.card.account,
                url: this.card.icon
            }]
        },
        // 设置缓存
        setStorageData() {
            this.$storage.set('influencer/registerBasic', {
                name: this.user.name,
                country: this.user.country,
                gender: this.user.gender == 'Female' ? 2 : 1
            })
            this.$storage.set('influencer/registerMedia', {
                siteList: this.user.siteList
            })
            this.$storage.set('influencer/userInfo', this.user)
        },
        // 显示修改密码弹窗
        handlePwd() {
            this.popup.visible = true
        },
        // 退出登录
        handleOut() {
            this.$Dialog.confirm({
                message: 'Are you sure to sign out?',
                confirmButtonText: 'Sign Out',
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#333',
                cancelButtonColor: '#666'
            }).then(() => {
                this.$storage.remove('token');
                this.$storage.remove('productInfo');
                this.$storage.remove('email');
                this.$storage.remove('chooseCouponInfo')
                this.$storage.remove('oneId');
                this.$storage.remove('twoId');
                this.$router.push({
                    name: 'Login'
                })
            }).catch(() => {})
        },
        // 跳转修改
        jumpRegister() {
            // 缓存设置
            this.setStorageData()
            this.$router.push({
                name: 'Influencer-Register',
                query: {
                    key: 2
                }
            })
        },
        // set HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: 'Influencer Program',
                keywords: 'yfn, influencer',
                description: '✔Free gifted jewelry ✔10% commission ✔20% exclusive discount! Refer and get rewarded now.',
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
        // 初始化
        initialSet() {
            this.middleEmit.fn = (fn, options) => {
                this[fn] && this[fn](options)
            }
            this.hasLogin = this.$login()
            this.cardIcon = [{
                type: 1,
                value: 1,
                text: 'Paypal',
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1686637847489.png'
            }, {
                type: 2,
                value: 2,
                text: 'Zelle',
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1686637862808.png'
            }, {
                type: 3,
                value: 3,
                text: 'Payoneer',
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1686637877322.png'
            }]
        }
    },
}
