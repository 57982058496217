
import fnApi from '/utils/common/methods'
import InfluencerHead from '../components/InfluencerHead'
import MinePopup from './components/MinePopup'

export default {
    name: 'Influencer-Mine',
    components: {
        InfluencerHead,
        MinePopup
    },
    asyncData({api, cookies, ...context}) {},
    props: {
        from: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            fnApi: fnApi,
            middleEmit: {},
            skeleton: {           // 骨架屏
                visible: true,
            },
            user: {},             // 网红信息
            orderList: [],
            couponInfo: {
                list: []
            },
            popup: {
                visible: false,
                type: 1,          // 1 link更改 2 code更改  3 欢迎弹窗
                title: '',
                info: {}
            },
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {
        this.initialSet()
    },
    mounted() {
        this.getUserInfoAjax()
    },
    methods: {// 网红信息接口
        getUserInfoAjax() {
            this.$api.influencer.getUserInfo().then(response => {
                const res = response.result;
                this.user = res || {}
                this.getUserCoupon()
            })
        },
        // 获取网红推广码、礼品码
        getUserCoupon() {
            this.$api.influencer.getUserCoupon({
                data: JSON.stringify({pageNum: 1, pageSize: 1})
            }).then(response => {
                const res = response.result;
                this.skeleton.visible = false
                this.couponInfo =  {
                    ...res,
                    list: []
                }
                this.setOrderList()
            })
        },
        // 设置订单数据
        setOrderList() {
            this.orderList = [{
                numText: this.couponInfo.totalOrderNum,
                title: 'Orders',
                url: 'Influencer-Order'
            }, {
                numText: this.couponInfo.totalSales,
                title: 'Sales',
                url: 'Influencer-Order'
            }, {
                numText: (this.couponInfo.commission / 100 || 0).toFixed(1),
                title: 'Earnings',
                url: 'Influencer-Earning'
            }]
            // 优惠码设置
            let rollList = (this.couponInfo.rollResult && this.couponInfo.rollResult.list) || []
            let rollObj = rollList[0] || {}
            let codePercent = parseFloat(rollObj.percent / 100, 2) || 0
            let code = rollObj.code || ''

            let giftList = (this.couponInfo.giftCodeResult && this.couponInfo.giftCodeResult.list) || []
            let giftObj = giftList[0] || {}
            let giftCode = giftObj.code || ''

            this.couponInfo.list = [{
                title: 'Your Referral Link',
                type: 1,
                desc: 'Refer your friends using the link below and earn commissions on purchases made by them',
                href: `https://www.yfn.com/?utm_id=${this.user.commissionUrl}`,
                code: this.user.commissionUrl
            }, {
                title: 'Your Coupon Code',
                type: 2,
                desc: 'Share your coupon code with others. For every purchase someone makes using your coupon code, you will get the commissions',
                percent: codePercent,
                code: code,
                codeText: `CODE:${code}`,
                id: rollObj.id || ''
            }]
            if(giftCode) {
                this.couponInfo.list.push({
                    title: 'Your Gift Jewelry Code',
                    type: 3,
                    desc: 'You can apply this code to purchase a jewelry under $50 for free on YFN.com (Exceeded costs will be borne by the influencer)',
                    percent: 100,
                    code: giftCode,
                    codeText: giftCode
                })
            }
            if(!this.from.hasSetting) {
                this.setPopup()
            }
        },
        // 设置popup
        setPopup() {
            this.popup.visible = true
            this.popup.type = 3
            this.popup.title = 'Welcome to Join YFN Influencer Club!'
        },
        // 复制
        handleCopy(obj) {
            this.fnApi.copy.initial(obj.href || obj.code)
            this.$Toast('Copied')
        },
        // 编辑
        handleEdit(obj) {
            this.popup.visible = true
            this.popup.info = obj
            this.popup.type = obj.type
            this.popup.title = ['Referral Link', 'Coupon Code'][obj.type - 1]
        },
        // 跳转
        jumpHome() {
            this.$router.push({
                name: 'Home'
            })
        },
        jumpOrder(obj) {
            this.$router.push({
                name: obj.url
            })
        },
        // emit
        emitEditLink() { // 修改link
            if(this.popup.type == 1) {
                this.user.commissionUrl = this.popup.info.code
                this.$set(this.couponInfo.list, 0, {...this.couponInfo.list[0], href: `https://www.yfn.com/?utm_id=${this.user.commissionUrl}`})
            }
        },
        emitEditCode(obj) { // 修改code
            if(this.popup.type == 2) {
                let code = this.popup.info.code
                this.$set(this.couponInfo.list, 1, {...this.couponInfo.list[1], code, codeText: `CODE:${code}`})
            }
        },
        // set HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: 'Influencer Program',
                keywords: 'yfn, influencer',
                description: '✔Free gifted jewelry ✔10% commission ✔20% exclusive discount! Refer and get rewarded now.',
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
        // 初始化
        initialSet() {
            this.middleEmit.fn = (fn, options) => {
                this[fn] && this[fn](options)
            }
        }
    },
}
